<script>
import {
    ArrowUpIcon,
    MailIcon,
    PhoneIcon,
    YoutubeIcon,
    MapPinIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    GithubIcon,
    GitlabIcon
} from 'vue-feather-icons';
import Navbar from "@/components/navbar";
import countTo from "vue-count-to";
import Footer from "@/components/footer";

/**
 * Helpcenter-overview component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        ArrowUpIcon,
        MailIcon,
        PhoneIcon,
        MapPinIcon,
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
        GithubIcon,
        YoutubeIcon,
        countTo,
        Footer,
        GitlabIcon
    }
}
</script>

<template>
<div>
    <Navbar :nav-light="false" />



    <!-- Hero Start -->
    <section class="bg-half bg-light sweet-violet-shade d-table w-100">

      <div class="bg-overlay" style="margin-bottom: 0px; background: url('images/background/karukaj-cover-1.png') center bottom;"></div>


      <div class="container mt-100 mt-60">
          <div class="row justify-content-center">
              <div class="col-lg-12 text-center">
                  <div class="page-next-level mt-60">

    



                     <div class="page-next">
                          <nav aria-label="breadcrumb" class="d-inline-block">
                              <ul class="breadcrumb bg-white rounded shadow mb-0">
                                  <li class="breadcrumb-item"><router-link to="/support"> <i class="mdi mdi-code-greater-than"></i> Support</router-link> </li>                          

                                  <li class="breadcrumb-item"><router-link to="/knowledgebase"> <i class="mdi mdi-code-greater-than-or-equal"></i> Knowledgebase</router-link></li>
                                  <li class="breadcrumb-item active" aria-current="Knowledgebase"> <i class="mdi mdi-clipboard-text"></i> Estimate</li>
                              </ul>
                          </nav>
                      </div>



                  </div>
              </div>
              <!--end col-->
          </div>
          <!--end row-->
      </div>
      <!--end container-->




  </section>
  <!--end section-->









    <section class="section">





      <div class="container mt-40 ">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">

                        <h4 class="title font-weight-bold hex-button">Get an Estimate</h4>

                        <p class="text-center ">
          We reply to more than
          <span class="my-color-1">
            <countTo
              :startVal="1"
              :endVal="100"
              :duration="4000"
            ></countTo
            >+ estimate requests/month
          </span>
         
          
          
          <span v-b-tooltip.hover title="Karukaj DIGITAL is one of the best digital marketing agency in Bangladesh near Dhaka, 
We offer digital marketing service and handle full operations. 
          Sign up and check our best website design price in bangladesh" class="my-color-1 font-weight-bold">*</span> 
        </p>




                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->






      <div class="container mt-60 mb-4">
        <div class="row align-items-center mb-4 pb-2">
          <div class="col-lg-6">
            <div class="section-title text-center text-lg-left">
              <h6 class="my-color-1">Honourable Entrepreneur!</h6>
              <h4 class="title mb-4 mb-lg-0">
                Submit an Estimate Form or 
               <br />Book an Appointment
                
              </h4>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6">
            <div class="section-title text-center text-lg-left">
              <p class="text-muted mb-0 mx-auto para-desc">
                
                We realize that the cost of digital development service is a concern for our members. So not to worry, you will receive an expert estimate of the market price of your plan.
                       
                  

              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

    <News />
        <!--end row-->
      </div>
      <!--end container-->






      <div class="container mt-40 ">
        <div class="row justify-content-center" id="counter">
          <div class="col-12 text-center">
            <div class="section-title">
              <h4 class="mb-0">
                <span class="mb-0"> We Believe,</span><br>
                Actions speak louder than words! 
                          </h4>
                        </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->






<div class="container mt-20">
  <div class="row justify-content-center" id="counter">
    <div class="col-12 text-center">
      <div class="section-title">

    
        

<div class="alert mb-4  alert-primary alert-pills shadow"
                      role="alert"
                    >
                      <span class="content">
                        Anyone can submit a esimate request</span
                      >
                    </div>









      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>
<!--end container-->

























        <div class="container mt-40">
            <div class="row align-items-center">










                <div class="col-lg-7 col-md-6 col-12">
                  <div class="card rounded bg-light shadow-lg border-0">
          <div class="card-body">

            <div class="title-heading">


              <h4 class="text-center font-weight-bold my-color-1">
                Get a free Price Estimate
            </h4>
             
               <p class="text-s text-center">
                Save your time to get it up and see everything OnBoard. 
                <br>You are just a few clicks away to get reply from us.
               </p>
                      </div>      
                      
                      
                      <iframe width="100%" height="640" src="https://business.karukaj.com.bd/forms/quote/45e759339042747f47df23db4d26987c" frameborder="0" allowfullscreen></iframe>



                    </div>       
                    
                    

                    </div>



                </div>

          







                <div class="col-lg-5 col-md-6 md-4 col-12 mt-sm-0 pt-2 pt-sm-0">




                  <div class=" carousel-cell">
<img width="100%" height="340" 
  src="images/explore/knowledgebase.png"
  class="img-fluid "
  alt=""
/>
</div>

<!--end col-->

<p class="text-muted mt-20 mx-auto">

  We pursue relationships based on transparency, persistence, mutual trust, and integrity with our employees, customers and other business partners.
  <br>
  <br>

Let us know by filling out this simple form.
A dedicated expert from our system will reply you or inform you about project estimate with details. 
</p>

              

<p class="text-muted font-weight-bold ">You can also chat with us via our favorite channels.</p>





<div class="widget">
                            <ul class="list-unstyled social-icon mb-0 mt-4">
                                <li class="list-inline-item"><a href="https://www.facebook.com/karukaj.digital" class="rounded">
                                        <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://www.instagram.com/karukaj.digital" class="rounded">
                                        <instagram-icon class="fea icon-sm fea-social"></instagram-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://www.twitter.com/karukaj_digital" class="rounded">
                                        <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://www.linkedin.com/company/karukajdigital" class="rounded">
                                        <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://karukaj.github.io/" class="rounded">
                                        <github-icon class="fea icon-sm fea-social"></github-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://youtube.com/karukaj" class="rounded">
                                        <youtube-icon class="fea icon-sm fea-social"></youtube-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://blog.karukaj.com.bd" class="rounded">
                                        <gitlab-icon class="fea icon-sm fea-social"></gitlab-icon>
                                    </a></li>
                            </ul>
                            <!--end icon-->

                        
                        </div>




<div class="my-color-2"><small>We hope you don't have to wait long for information.</small></div>















</div>











            </div>



            
        </div>
        <!--end container-->




















   
        <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center mt-40">
       
              <div class="mt-4 pt-2">




                 <h5 class="mb-4 font-weight-bold">Are you new to digital marketing?</h5>



                 <router-link  class="btn btn-primary  font-weight-bold text-center" to="/knowledgebase"  v-b-tooltip.hover title="Explore digital marketing knowledge within a few of our topic overview and our recommendations.."   ><i class="mdi mdi-book-open-variant"></i> Read Knowledgebase<i class="mdi mdi-chevron-right"></i> </router-link> 

                
                 
  
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->






    </section>
    <!--end section-->
    <!-- End Section -->





    <!--end section-->
    <!-- End Section -->




    <section
      class="section bg-white pt-0"
      :style="{
        background:
          'url(' +
          require('../../public/images/real/build2.png') +
          ') bottom no-repeat',
      }"
    >
  
      
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">


            <br><br> <br>    <br><br> <br> <br>

          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!--end container-->
    </section>
    <!--end section-->






    


    <!-- End -->
<div class="position-relative">
        <div class="shape overflow-hidden text-footer ">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="#4e4e4e"></path>
            </svg>
        </div>
    </div>
    <!--end section-->

    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-primary btn-icon back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>


</template>

